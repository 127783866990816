import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import kalam from "./j0l0zm9f.png";
import MapIcon from '@mui/icons-material/Map';
import CallIcon from '@mui/icons-material/Call';

const Home = () => {


    return (
        <div className="container-fluid px-0"  style={{ "backgroundSize": "cover", "height": "100vh" }}>
            <title>Home</title>
            <div style={{ backgroundImage:`url(${kalam})`,  "filter": "blur(8px)",
    "-webkit-filter": "blur(2px) brightness(30%)", "height": "100vh" }}></div>
            <h2 className="title">Kasab Handicrafts</h2>
            <a href="https://maps.app.goo.gl/QDLHTYGGnAyWkxXR8"><MapIcon className="mapI"  color="white" variant="contained">Hello world</MapIcon></a>
            <a href="tel:+919819822750"><CallIcon className="callI" fontSize='large' variant="contained">Hello world</CallIcon></a>
            <div className='subT'>Website launching soon!</div>

        </div>
    );
};

export default Home;
